import React, { Component } from 'react';
import ziPic from './zi.jpg';
import './App.css';
import { Image, Row, Col, Container } from 'react-bootstrap'

const hatSrc = './img/hat2.png'
const bagSrc = './img/bag2.png'
const folderSrc = './img/folder.png'
const toolsSrc = './img/code.png'
const handSrc = './img/handshake2.png'
const languageSrc = './img/language2.png'
const otherXpSrc = './img/engrenage2.png'
const personSrc = './img/person.png'
const checklistSrc = './img/checklist2.png'

class App extends Component {
  constructor(){
    super();
    this.state = {
      isFrench: false
    }
  }

  toggleLanguage(){
    this.setState({isFrench: !this.state.isFrench});
  }

  englishResume(){
    return (<div class="container pt-5" style={{maxWidth:"1350px"}}>
      <div class="row">
        <div class="col-12 col-lg-9">
          <div class="row">
            <div class="col-3 col-lg-2">
              <Image /*onClick={this.toggleLanguage.bind(this)}*/ width="100%" src={ziPic} roundedCircle />
            </div>
            <div class="col-8 col-lg-9 mt-lg-3 mt-2">
              <h3>Zi Long Li</h3>
              <h6 class="mb-1">Let's do something challenging</h6>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-1">
            </div>
            <div class="col-lg-10 col-12">
              <div class="row mb-2">
                <div class="col-lg-1 col-2 px-2">
                  <Image width="100%" src={hatSrc} roundedCircle />
                </div>
                <div class="col-10 col-lg-5 px-0 pt-lg-3 pt-2">
                  <h5 class="align-middle">Education</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-10 offset-1 px-0">
                  <h6 class="mb-1">Bachelor of Science, Computer Science major | <a target="_blank" href="https://www.usherbrooke.ca/about/">Université de Sherbrooke</a></h6>
                  <p>2016 – 2018</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-1">
            </div>
            <div class="col-lg-10 col-12">
              <div class="row mb-2">
                <div class="col-lg-1 col-2 px-2">
                  <Image width="100%" src={bagSrc} roundedCircle />
                </div>
                <div class="col-10 col-lg-5 px-0 pt-lg-3 pt-2">
                  <h5 class="align-middle">Professionnal Experiences</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-10 offset-1 px-0">
                  <h6 class="mb-1">Software Engineer Intern, Machine Learning Backend | <a target="_blank" href="https://www.coveo.com/en">Coveo Solutions</a></h6>
                  <p class="mb-0">Apr - Aug 2018</p>
                  <p class="ml-2">
                  •	Implement a new multi-tenant structure for a new model in Machine Learning <br/>
                  •	Use different services in AWS (EMR, S3, DynamoDB, SQS…)<br/>
                  •	Develop programs in Java8, spring and test with JUnit and Mockito<br/>
                  •	Create and update new REST API<br/>
                  </p>
                  <h6 class="mb-1">Software Engineer Intern, Hybris Marketing | <a target="_blank" href="https://www.sap.com/canada/index.html">SAP Labs Canada</a></h6>
                  <p class="mb-0">May - Aug 2017</p>
                  <p class="ml-2">
                  •	Implement key software features for a giant customer <br/>
                  •	Design an optimistic locking using ABAP with Etags <br/>
                  •	Analyze edges cases and conceive unit and OPA test (Integration)<br/>
                  •	Refactor existing code and solve dependencies issues<br/>
                  </p>
                  <h6 class="mb-1">Tech Analyst Intern, ICT (Institutional & Corporate Tech) | <a target="_blank" href="https://www.morganstanley.com/">Morgan Stanley</a></h6>
                  <p class="mb-0">Jan - Aug 2016</p>
                  <p class="ml-2">
                  •	Analyze and optimize an internal database comparator using Python<br/>
                  •	Improve schema extraction time by up to 98%<br/>
                  •	Generate automatically scripts to fix differences between databases<br/>
                  •	Work on a UNIX server and using source code control such as Git<br/>
                  </p>
                  <h6 class="mb-1">Software Developer Intern | <a target="_blank" href="http://www.giro.ca/en/">Giro Inc.</a></h6>
                  <p class="mb-0">May - Aug 2015</p>
                  <p class="ml-2">
                  • Develope an option for adding new patterns in the GUI<br/>
                  • Exploite C++ data structures and learning an internal programming language<br/>
                  • Restructure and break down the main DLL of HASTUS™ software<br/>
                  • Create new modules and DLL<br/>
                  • Communicate constantly with project manager to improve features<br/>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-1">
            </div>
            <div class="col-lg-10 col-12">
              <div class="row mb-2">
                <div class="col-lg-1 col-2 px-2">
                  <Image width="100%" src={otherXpSrc} roundedCircle />
                </div>
                <div class="col-10 col-lg-5 px-0 pt-lg-3 pt-2">
                  <h5 class="align-middle">Projects and competitions</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-10 offset-1 px-0">
                  <h6 class="mb-1">Picture Finder (<a target="_blank" href="https://beta.sherlome.com">Sherlome</a>) – 1-Person Project</h6>
                  <p class="mb-0">Nov 2018</p>
                  <p class="ml-2">
                  •	Find specific pictures within millions using Facial Recognition<br/>
                  •	Optimize the backend to be able to do 15000 pictures searches per second (~35000 faces)<br/>
                  •	Build 90% of the current website using React and Material UI within a day<br/>
                  •	Building an API using Flask and manage SSL for HTTPS<br/>
                  </p>
                  <h6 class="mb-1">Ridesharing Finder (<a target="_blank" href="https://www.eckoride.com">EckoRide</a>) – 1-Person Project</h6>
                  <p class="mb-0">Mar 2018</p>
                  <p class="ml-2">
                  •	Create a complementary website to my ridesharing Facebook groups<br/>
                  •	Fetch, analyze and sort the Facebook posts with a self-made algorithm<br/>
                  •	Display the posts in a responsive UI with React and Bootstrap<br/>
                  •	Averaging 3500 pages views per week with 94% user satisfaction<br/>
                  </p>
                  <h6 class="mb-1">CS Games 2017 – Team Roberto Ménard</h6>
                  <p class="mb-0">Mar 2018</p>
                  <p class="mb-0">Participating in those disciplines:</p>
                  <p class="ml-2">
                  •	Theoretical Computer Science <br/>
                  •	CSE - CST<br/>
                  •	Sport & Code<br/>
                  </p>
                  <h6 class="mb-1">CS Games 2017 – Team Ramsay</h6>
                  <p class="mb-0">Mar 2017</p>
                  <p class="mb-0">Participating in those disciplines:</p>
                  <p class="ml-2">
                  •	Reverse engineering <br/>
                  •	Debugging - Move fast, break fast!<br/>
                  •	CSE - Corporate Espionage at Big Org<br/>
                  </p>
                  <h6 class="mb-1">Startup Weekend Sherbrooke – Winner Team</h6>
                  <p class="mb-0">Mar 2016</p>
                  <p class="ml-2">
                  •	Create a new tagging and tracking system for beer kegs<br/>
                  •	Build a smart balance by reverse engineering an existing one<br/>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-1">
            </div>
            <div class="col-lg-10 col-12">
              <div class="row mb-2">
                <div class="col-lg-1 col-2 px-2">
                  <Image width="100%" src={handSrc} roundedCircle />
                </div>
                <div class="col-10 col-lg-5 px-0 pt-lg-3 pt-2">
                  <h5 class="align-middle">Volunteering</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-10 offset-1 px-0">
                <p>
                - Statistics at improvisation league of Sherbrooke (LUIS) - 2019 <br/>
                - <a target="_blank" href="https://operationnezrouge.com/en/qc/1310/historique/">Operation Red Nose</a> - 2019<br/>
                - Ridesharing groups administrator: <br/>
                  <a class="ml-3" target="_blank" href="https://www.facebook.com/groups/CovoiturageSherbrookeMontreal/">Covoiturage Montreal @ Sherbrooke</a> <br/>
                  <a class="ml-3" target="_blank" href="https://www.facebook.com/groups/CovoiturageSherbrookeQuebec/">Covoiturage Sherbrooke-Québec</a> <br/>
                - Hot dog maker for <a target="_blank" href="https://danslarue.org/en/">Dans la rue</a> - 2016<br/>
                - Food keeper for <a target="_blank" href="https://www.accueilbonneau.com/english">Accueil Bonneau</a> - 2011
                </p>  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-12">
          <div class="row mb-4">
            <div class="col-2 col-lg-3 px-2">
              <Image width="100%" src={personSrc} roundedCircle />
            </div>
            <div class="col-lg-9 col-10 px-0 pt-lg-3 pt-2">
              <h5 class="align-middle">Personal Infos</h5>
            </div>
          </div>
          <h6 class="mb-1">Residence </h6>
          <p>Montreal, Qc </p>
          <h6 class="mb-1">E-mail</h6>
          <p>Zi.Long.Li@USherbrooke.ca </p>
          <h6 class="mb-1">LinkedIn</h6>
          <p><a target="_blank" href="https://www.linkedin.com/in/zilongl/">https://www.linkedin.com/in/zilongl/</a></p>
          <div class="row w-100 my-4 mr-0">
            <div class="col-2 col-lg-3 px-2">
              <Image width="100%" src={toolsSrc} roundedCircle />
            </div>
            <div class="col-lg-9 col-10 px-0 pt-lg-3 pt-2">
              <h5 class="align-middle">Programming Languages</h5>
            </div>
          </div>
          <p class="float-none">Python</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵🔵⚪</p>
            <p class="float-right">Advanced</p>
          </div>
          <br/>
          <p class="float-none">Javascript</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵🔵⚪</p>
            <p class="float-right">Advanced</p>
          </div>
          <br/>
          <p class="float-none">Java</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵🔵⚪</p>
            <p class="float-right">Advanced</p>
          </div>
          <br/>
          <p class="float-none">C++</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵⚪⚪</p>
            <p class="float-right">Intermediate</p>
          </div>
          <br/>
          <div class="row w-100 my-4 mr-0">
            <div class="col-2 col-lg-3 px-2">
              <Image width="100%" src={checklistSrc} roundedCircle />
            </div>
            <div class="col-lg-9 col-10 px-0 pt-lg-3 pt-2">
              <h5 class="align-middle">Others</h5>
            </div>
          </div>
          <p class="float-none">React</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵🔵⚪</p>
            <p class="float-right">Advanced</p>
          </div>
          <br/>
          <p class="float-none">Git</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵🔵⚪</p>
            <p class="float-right">Advanced</p>
          </div>
          <br/>
          <p class="float-none">SQL</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵🔵⚪</p>
            <p class="float-right">Advanced</p>
          </div>
          <br/>
          <p class="float-none">Linux</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵⚪⚪</p>
            <p class="float-right">Intermediate</p>
          </div><br/>
          <p class="float-none">Spring</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵⚪⚪</p>
            <p class="float-right">Intermediate</p>
          </div>
          <div class="row w-100 my-4 mr-0">
            <div class="col-2 col-lg-3 px-2">
              <Image width="100%" src={languageSrc} roundedCircle />
            </div>
            <div class="col-lg-9 col-10 px-0 pt-lg-3 pt-2">
              <h5 class="align-middle">Language</h5>
            </div>
          </div>
          <p class="float-none">French</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵🔵🔵</p>
            <p class="float-right">Advanced</p>
          </div>
          <br/>
          <p class="float-none">English</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵🔵⚪</p>
            <p class="float-right">Advanced</p>
          </div>
          <br/>
          <p class="float-none">Mandarin</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵🔵⚪</p>
            <p class="float-right">Advanced</p>
          </div>
          
        </div>
      </div>
    </div>
    );
  }

  frenchResume(){
    return (<div class="container pt-5" style={{maxWidth:"1350px"}}>
      <div class="row">
        <div class="col-9">
          <div class="row">
            <div class="col-2">
              <Image onClick={this.toggleLanguage.bind(this)} width="100%" src="https://media.licdn.com/dms/image/C5603AQHYaUtLeLFO8w/profile-displayphoto-shrink_800_800/0?e=1556150400&v=beta&t=RRFJig-6go5VvRW5CyHpdE2SLyNBDgGTr25vIY5i6cQ" roundedCircle />
            </div>
            <div class="col-9 mt-3">
              <h3>Valérie Beaupré</h3>
              <h6 class="mb-1">Étudiante à l'Université de Sherbrooke</h6>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-1">
              <p>2013-2019</p>
            </div>
            <div class="col-lg-10 col-12">
              <div class="row mb-2">
                <div class="col-lg-1 col-2 px-2">
                  <Image width="100%" src={hatSrc} roundedCircle />
                </div>
                <div class="col-10 col-lg-5 px-0 pt-lg-3 pt-2">
                  <h5 class="align-middle">Éducation</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-10 offset-1 px-0">
                  <h6 class="mb-1">M.Sc. Finance | Université de Sherbrooke</h6>
                  <p>2018 – 2019</p>
                  <h6 class="mb-1">Baccalauréat en administration des affaires  | Université de Sherbrooke</h6>
                  <h6 class="mb-1">PROFIL COMPTABILITÉ</h6>
                  <p>2016 – 2017</p>
                  <h6 class="mb-1">PROFIL FINANCE</h6>
                  <p>2013 – 2016</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-1">
              <p>2015-2016</p>
            </div>
            <div class="col-lg-10 col-12">
              <div class="row mb-2">
                <div class="col-lg-1 col-2 px-2">
                  <Image width="100%" src={bagSrc} roundedCircle />
                </div>
                <div class="col-10 col-lg-5 px-0 pt-lg-3 pt-2">
                  <h5 class="align-middle">Expériences Professionnelles </h5>
                </div>
              </div>
              <div class="row">
                <div class="col-10 offset-1 px-0">
                  <h6 class="mb-1">Stagière en Finance (Programme de Rotation)  | Financière Banque Nationale</h6>
                  <p class="mb-0">2016</p>
                  <p class="ml-2">
                  -	Interprétation des ratios financiers et des données sectorielles des actions<br/>
                  -	Élaboration de stratégies obligataires et de scénarios de réallocation d’actifs<br/>
                  -	Analyse des implications fiscales considérant les choix de titres financiers et du type de compte<br/>
                  -	Collaboration à l’évaluation la relation risque-rendement des portefeuilles des investisseurs<br/>
                  -	Optimisation de fichiers par l’automatisation de mises à jour entre les bases de données<br/>

                  </p>
                  <h6 class="mb-1">Analyste financier | Bombardier Aéronautique</h6>
                  <p class="mb-0">2015</p>
                  <p class="ml-2">
                  -	Collaboration avec mon gestionnaire quant au suivi des coûts de temps supplémentaires de la main-d’œuvre<br/>
                  -	Exécution des tâches comptables associées à la clôture de comptes<br/>
                  -	Validation des dépenses d'inventaire<br/>
                  -	Élaboration des tables de déclaration de crédits d'impôt et des prévisions des compte de résultat<br/>
                  -	Communication avec diverses équipes génératrices de coûts, principalement les chefs de projet<br/>

                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-1">
              <p>2013-2017</p>
            </div>
            <div class="col-lg-10 col-12">
              <div class="row mb-2">
                <div class="col-lg-1 col-2 px-2">
                  <Image width="100%" src={otherXpSrc} roundedCircle />
                </div>
                <div class="col-10 col-lg-5 px-0 pt-lg-3 pt-2">
                  <h5 class="align-middle">Autres Expériences</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-10 offset-1 px-0">
                  <h6 class="mb-1">Serveuse | Restaurant Demers</h6>
                  <p>2017</p>
                  <h6 class="mb-1">Conseillère en Vente | La Maison Simons</h6>
                  <p>2015 - 2016</p>
                  <h6 class="mb-1">Commis à la gestion des données maîtres  | Metro Inc (Siège Social)</h6>
                  <p class="mb-0">2013-2016</p>
                  <p class="ml-2">
                  -	Commis à la gestion des données maîtres <br/>
                  -	Analyse, coordination et validation des informations avec le département de la négociation centrale
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-1">
            </div>
            <div class="col-lg-10 col-12">
              <div class="row mb-2">
                <div class="col-lg-1 col-2 px-2">
                  <Image width="100%" src={folderSrc} roundedCircle />
                </div>
                <div class="col-10 col-lg-5 px-0 pt-lg-3 pt-2">
                  <h5 class="align-middle">Perfectionnements</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-10 offset-1 px-0">
                  <p>
                  - Inscription au titre Chatered Financial Analyst (CFA) <br/>
                  - Participation à la simulation boursière «Objectif Wall Street»<br/>
                  - Participation à la simulation boursière « TMX–Montréal Exchange »<br/>
                  - Participation aux jeux du commerce dans la discipline simulation boursière<br/>
                  - Formation Bloomberg
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-1">
            </div>
            <div class="col-lg-10 col-12">
              <div class="row mb-2">
                <div class="col-lg-1 col-2 px-2">
                  <Image width="100%" src={handSrc} roundedCircle />
                </div>
                <div class="col-10 col-lg-5 px-0 pt-lg-3 pt-2">
                  <h5 class="align-middle">Engagements étudiants</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-10 offset-1 px-0">
                <p>
                - Vice-Présidente Finance de l’association étudiante RÉFUS<br/>
                - Organisatrice de levés de fond et de conférences financières<br/>
                - Ironman 70.3 Mont-Tremblant 
                </p>  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="row mb-4">
            <div class="col-2 col-lg-3 px-2">
              <Image width="100%" src={personSrc} roundedCircle />
            </div>
            <div class="col-lg-9 col-10 px-0 pt-lg-3 pt-2">
              <h6 class="align-middle">Informations personnelles</h6>
            </div>
          </div>
          <h6 class="mb-1">Résidence </h6>
          <p>Repentigny, Qc </p>
          <h6 class="mb-1">Téléphone</h6>
          <p>(438) 881-7675 </p>
          <h6 class="mb-1">E-mail</h6>
          <p>Valerie.Beaupre@USherbrooke.ca </p>
          <h6 class="mb-1">LinkedIn</h6>
          <p>https://www.linkedin.com/in/valérie-beaupré/</p>
          <div class="row w-100 my-4 mr-0">
            <div class="col-2 col-lg-3 px-2">
              <Image width="100%" src={toolsSrc} roundedCircle />
            </div>
            <div class="col-lg-9 col-10 px-0 pt-lg-3 pt-2">
              <h5 class="align-middle">Logiciels</h5>
            </div>
          </div>
          <p class="float-none">MATLAB</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵🔵⚪</p>
            <p class="float-right">Avancée</p>
          </div>
          <br/>
          <p class="float-none">Eviews</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵🔵⚪</p>
            <p class="float-right">Avancée</p>
          </div>
          <br/>
          <p class="float-none">VBA</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵🔵⚪</p>
            <p class="float-right">Avancée</p>
          </div>
          <br/>
          <p class="float-none">Stata</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵⚪⚪</p>
            <p class="float-right">Intermédiaire</p>
          </div>
          <br/>
          <p class="float-none">SAP</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵⚪⚪</p>
            <p class="float-right">Intermédiaire</p>
          </div>
          <br/>
          <p class="float-none">Coesus</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵⚪⚪</p>
            <p class="float-right">Intermédiaire</p>
          </div>
          <br/>
          <div class="row w-100 my-4 mr-0">
            <div class="col-2 col-lg-3 px-2">
              <Image width="100%" src={languageSrc} roundedCircle />
            </div>
            <div class="col-lg-9 col-10 px-0 pt-lg-3 pt-2">
              <h5 class="align-middle">Langues</h5>
            </div>
          </div>
          <p class="float-none">Français</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵🔵⚪</p>
            <p class="float-right">Avancée</p>
          </div>
          <br/>
          <p class="float-none">Anglais</p>
          <div class="float-right">
            <p class="mb-0">🔵🔵🔵🔵⚪⚪</p>
            <p class="float-right">Intermédiaire</p>
          </div>
          
        </div>
      </div>
    </div>
    );
  }

  render() {
    const { isFrench } = this.state;
    return (
      <div>
      {isFrench ? this.frenchResume() : this.englishResume()}
      </div>
    );
  }
}

export default App;
